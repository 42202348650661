<template>
    <div>

        <div class="row">
            <div class="col-12">
                <div class="card bd-1 bd-white-1 pd-10">
                    <div class="card-header bg-transparent pd-0 bd-b-0">
                        <h5 class="tx-14 tx-uppercase tx-spacing-1 tx-semibold">Filter Report</h5>
                    </div><!-- card-header -->
                    <div class="card-block pd-0">

                        <div class="row row-sm mg-t-10">
                            <div class="col-6 mg-t-10 mg-md-t-0">
                                <div class="form-group mg-b-0">
                                    <label class="d-block">Site</label>
                                    <div>
                                        <select v-model="filters.site_id" class="form-control form-control-dark select2" multiple="multiple" v-select2>
                                            <option v-for="s in sites" v-bind:value="s.id">{{$store.state.auth.demo ? 'Site' : s.name}}</option>
                                        </select>
                                    </div>
                                </div><!-- form-group -->
                            </div><!-- col-3 -->
                            <div class="col-3 mg-t-10 mg-md-t-0">
                                <div class="form-group mg-b-0">
                                    <label class="d-block">Type</label>
                                    <div>
                                        <select v-model="filters.type" class="form-control form-control-dark select2" multiple="multiple" v-select2>
                                            <option v-if="$can('nmop-view')" :value="'nmop'">No Means of Payment</option>
                                            <option v-if="$can('driveoffs-view')" :value="'driveoff'">Drive Off</option>
                                            <option v-if="$can('parking-view')" :value="'parking'">Parking</option>
                                        </select>
                                    </div>
                                </div><!-- form-group -->
                            </div><!-- col-3 -->
                            <div class="col-3 mg-t-10 mg-md-t-0">
                                <div class="form-group mg-b-0">
                                    <label class="d-block">Status</label>
                                    <div>
                                        <select v-model="filters.status" class="form-control form-control-dark select2" multiple="multiple" v-select2>
                                            <option v-for="s in statuses" v-bind:value="s">{{s}}</option>
                                        </select>
                                    </div>
                                </div><!-- form-group -->
                            </div><!-- col-3 -->
                        </div><!-- row -->
                        <div class="row row-sm mg-t-10">
                            <div class="col-3 mg-t-10 mg-lg-t-0">
                               <div class="form-group mg-b-0">
                                   <label>Plate</label>
                                   <input type="text" v-model="filters.plate" class="form-control form-control-dark tx-14" placeholder="Plate">
                               </div>
                            </div>
                            <div class="col-3 mg-t-10 mg-lg-t-0">
                                <div class="form-group mg-b-0">
                                    <label>Start Date</label>
                                    <input type="date" v-model="filters.start_date" class="form-control" placeholder="DD/MM/YYYY">
                                </div><!-- form-group -->
                            </div><!-- col-2 -->
                            <div class="col-3 mg-t-10 mg-lg-t-0">
                                <div class="form-group mg-b-0">
                                    <label>End Date</label>
                                    <input type="date" v-model="filters.end_date" class="form-control" placeholder="DD/MM/YYYY">
                                </div><!-- form-group -->
                            </div><!-- col-2 -->
                            <div v-if="filters.status.includes('Paid')" class="col-3 mg-t-10 mg-md-t-0">
                                <div class="form-group mg-b-0">
                                    <label class="d-block">Sort By</label>
                                    <div>
                                        <select v-model="filters.sort_by" class="form-control form-control-dark">
                                            <option value="paid">Date Paid</option>
                                            <option value="any">Date Received</option>
                                        </select>
                                    </div>
                                </div><!-- form-group -->
                            </div><!-- col-3 -->
                        </div>

                        <div class="row row-sm mg-t-10">
                            <div class="col-12 d-flex">
                                <button class="btn btn-sm btn-primary" @click="thisWeek">This Week</button>
                                <button class="btn btn-sm btn-primary mg-l-20" @click="thisMonth">This Month</button>
                                <button class="btn btn-sm btn-primary mg-l-20" @click="thisQuarter">This Quarter</button>
                                <button class="btn btn-sm btn-primary mg-l-20" @click="thisYear">This Year</button>
                            </div>
                        </div>

                        <a href="javascript:void(0);" @click="generate" class="btn btn-info tx-11 tx-spacing-1 tx-uppercase tx-semibold tx-mont pd-y-12 pd-x-30 mg-t-10">Generate Report</a>
                    </div><!-- card-block -->
                </div>
            </div>
        </div>

        <div class="row mg-t-20">
            <div class="col-12">

                <loader :show="generating"></loader>
                <div v-if="!generating">

                    <ul v-if="errors.length > 0">
                        <li v-for="e in errors" class="tx-danger">{{e}}</li>
                    </ul>

                    <p class="tx-warning tx-bold" v-if="claims.length < 1">No Claims found that match your search results.</p>

                    <div class="row row-sm mg-b-20" v-show="claims.length > 0">
                        <div class="col-sm-6 col-x1-6">
                            <div class="bg-transfile rounded overflow hidden" :title="forecastPaid">
                                <div class="pd-x-20 pd-t-20 pd-b-10 d-flex align-items-center">
                                    <div class="mg-1-20">
                                        <p class="tx-12 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Total Paid</p>
                                        <p class="tx-24 tx-white tx-lato tx-bold mg-b-0 1h-1">{{totalPaid.toLocaleString()}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-x1-6">
                            <div class="bg-transfile rounded overflow hidden">
                                <div class="pd-x-20 pd-t-20 pd-b-10 d-flex align-items-center">
                                    <div class="mg-1-20">
                                        <p class="tx-12 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Still being Recovered</p>
                                        <p class="tx-24 tx-white tx-lato tx-bold mg-b-0 1h-1">{{ totalUnrecovered.toLocaleString() }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row row-sm mg-b-20" v-show="claims.length > 0">
                        <div class="col-sm-4 col-x1-4">
                            <div class="bg-transfile rounded overflow hidden">
                                <div class="pd-x-20 pd-t-20 pd-b-10 d-flex align-items-center">
                                    <div class="mg-1-20">
                                        <p class="tx-12 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Drive Off</p>
                                        <p class="tx-24 tx-white tx-lato tx-bold mg-b-0 1h-1">{{numberOfDriveOff.toLocaleString()}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-x1-4">
                            <div class="bg-transfile rounded overflow hidden">
                                <div class="pd-x-20 pd-t-20 pd-b-10 d-flex align-items-center">
                                    <div class="mg-1-20">
                                        <p class="tx-12 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">No Means of Payment</p>
                                        <p class="tx-24 tx-white tx-lato tx-bold mg-b-0 1h-1">{{numberOfNoMeans.toLocaleString()}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-x1-4">
                            <div class="bg-transfile rounded overflow hidden">
                                <div class="pd-x-20 pd-t-20 pd-b-10 d-flex align-items-center">
                                    <div class="mg-1-20">
                                        <p class="tx-12 tx-spacing-1 tx-mont tx-semibold tx-uppercase tx-white-8 mg-b-10">Parking</p>
                                        <p class="tx-24 tx-white tx-lato tx-bold mg-b-0 1h-1">{{numberOfParking.toLocaleString()}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button v-if="claims.length > 0" class="btn float-right tx-white tx-uppercase tx-bold mg-b-10" @click="exportCsv" style="background-color: #1D6F42;">Export CSV <i class="fas fa-file-csv mg-l-10 mg-r-5"></i></button>
                    <button v-if="claims.length > 0" class="btn float-right tx-white tx-uppercase tx-bold mg-b-10 mg-r-10" @click="exportExcel" style="background-color: #1D6F42;">Export Excel <i class="fas fa-file-excel mg-l-10 mg-r-5"></i></button>

                    <table v-show="claims.length > 0" width="100%" class="table table-striped table-hover tx-white">
                        <thead class="thead-colored thead-primary">
                        <tr>
                            <th>TYPE</th>
                            <th>REFERENCE</th>
                            <th>SITE</th>
                           <th>PLATE</th>
                            <th>DATE RECEIVED</th>
                            <th>STATUS</th>
                            <th></th>
                            <th>VALUE</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="c in claims">
                            <td v-if="c.type === 'nmop'">No Means of Payment</td>
                            <td v-if="c.type === 'escalated'">No Means of Payment <span class="tx-warning">Escalated</span></td>
                            <td v-if="c.type === 'driveoff'">Drive Off</td>
                            <td v-if="c.type === 'parking'">Parking</td>
                            <td v-if="c.type === 'cnaf'">Customer Not at Fault</td>
                            <td v-if="c.type === 'cnafe'">Customer Not at Fault <span class="tx-warning">Escalated</span></td>
                            <td>{{$store.state.auth.demo ? 'Reference' : c.reference}}</td>
                            <td>{{$store.state.auth.demo ? 'Site' : c.site.name}}</td>
                           <td>{{c.registration}}</td>
                            <td>{{c.date_received}}</td>
                            <td>{{c.status}} <span v-if="c.status === 'Debt Collection' && c.debt_collection_stage !== null">({{c.debt_collection_stage}})</span></td>
                            <td><span v-if="c.status === 'Cancelled'">{{c.cancellation_reason}}</span><span v-if="c.status === 'Paid' || c.status === 'Paid on Site'">Paid on {{c.date_paid}} ({{parseFloat(c.daysTillPayment).toFixed(0)}})</span></td>
                            <td>{{c.value.toLocaleString()}}</td>
                            <td v-if="c.type === 'parking'">
                                <button v-if="c.status !== 'Cancelled' && c.status !== 'Paid' && c.status !== 'Paid on Site'" class="btn btn-sm btn-danger" @click="cancelClaim(c)">Request Cancellation</button>
                                <button class="btn btn-sm btn-info" @click="viewClaimParking(c.id)">View Details</button>
                            </td>
                            <td v-else>
                                <button v-if="c.status !== 'Cancelled' && c.status !== 'Paid'" class="btn btn-sm btn-danger" @click="cancelClaim(c)">Request Cancellation</button>
                                <button class="btn btn-sm btn-info" @click="viewClaim(c.id)">View Details</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </div>
        <div id="fuelModal" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Claim Details</h6>
                        <button type="button" class="close tx-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">
                        <loader :show="!viewingClaim"></loader>
                        <div v-if="viewingClaim">

                            <p v-if="claim.type === 'escalated'" class="tx-warning mg-b-10 tx-bold tx-uppercase">This claim is an escalated no means of payment.</p>

                            <p class="font-weight-bold">Claim Details</p>

                            <table class="table table-striped table-hover tx-white" width="100%">
                                <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>Pump</th>
                                    <th>User</th>
                                    <th>Site</th>
                                    <th>Incident Timestamp</th>
                                    <th>Status</th>
                                    <th>Reference</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{claim.pump}}</td>
                                    <td>{{(claim.user || {email: 'Kiosk'}).email}}</td>
                                    <td>{{claim.site.name}}</td>
                                    <td>{{created_at}}</td>
                                    <td>{{claim.status}}</td>
                                    <td>{{claim.reference}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <br/>
                            <p class="font-weight-bold">Fuel Details</p>
                            <table class="table table-striped table-hover tx-white" width="100%">
                                <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>Fuel</th>
                                    <th>Litres</th>
                                    <th>Value</th>
                                    <th v-if="claim.fuel_second !== null">Second Fuel</th>
                                    <th v-if="claim.fuel_second !== null">Litres</th>
                                    <th v-if="claim.fuel_second !== null">Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{claim.fuel}}</td>
                                    <td>{{Number.parseFloat(claim.litres).toFixed(2)}}</td>
                                    <td>£{{Number.parseFloat(claim.value).toFixed(2)}}</td>
                                    <td v-if="claim.fuel_second !== null">{{claim.fuel_second}}</td>
                                    <td v-if="claim.fuel_second !== null">{{Number.parseFloat(claim.litres_second).toFixed(2)}}</td>
                                    <td v-if="claim.fuel_second !== null">£{{Number.parseFloat(claim.value_second).toFixed(2)}}</td>
                                </tr>
                                </tbody>
                            </table>

                            <br>

                            <p class="font-weight-bold">Vehicle Information</p>

                            <table class="table table-striped table-hover tx-white" width="100%">
                                <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>Plate</th>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Colour</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{claim.vehicle.plate}}</td>
                                    <td>{{claim.make}}</td>
                                    <td>{{claim.model}}</td>
                                    <td>{{claim.colour}}</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button type="button" class="btn btn-secondary tx-size-xs" @click="closeClaim" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>

        <div id="parkingModal" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Claim Details</h6>
                        <button type="button" class="close tx-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">
                        <loader :show="!viewingClaim"></loader>
                        <div v-if="viewingClaim">

                            <p class="font-weight-bold">Claim Details</p>

                            <table class="table table-striped table-hover tx-white" width="100%">
                                <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>User</th>
                                    <th>Site</th>
                                    <th>Incident Timestamp</th>
                                    <th>Status</th>
                                    <th>Reference</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{(claim.user || {email: 'Kiosk'}).email}}</td>
                                    <td>{{claim.site.name}}</td>
                                    <td>{{created_at}}</td>
                                    <td>{{claim.status}}</td>
                                    <td>{{claim.reference}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <br/>

                            <p class="font-weight-bold">Parking Details</p>
                            <table class="table table-striped table-hover tx-white" width="100%">
                                <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>Entry Time</th>
                                    <th>Exit Time</th>
                                    <th>Allowed Stay</th>
                                    <th>Overstayed By</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{entry}}</td>
                                    <td>{{exit}}</td>
                                    <td>{{maxStay}}</td>
                                    <td>{{overstayBy}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <br/>

                            <p class="font-weight-bold">Vehicle Information</p>

                            <table class="table table-striped table-hover tx-white" width="100%">
                                <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>Plate</th>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Colour</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{claim.vehicle.plate}}</td>
                                    <td>{{claim.make}}</td>
                                    <td>{{claim.model}}</td>
                                    <td>{{claim.colour}}</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button type="button" class="btn btn-secondary tx-size-xs" @click="closeClaim" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>

        <div id="cancelClaimModal" class="modal fade" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content bd-0 tx-14">
                    <div class="modal-header pd-y-20 pd-x-25">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">Request a Cancellation</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div v-if="cancelClaimData.claim" class="modal-body pd-25">
                        <p class="mg-b-5">Please select the reason you wish to cancel the case from the available options. If none of the options are relevant please explain why you
                        wish to close the case using the input provided.</p>
                        <div class="form-group">
                            <select placeholder="Please select a reason" v-model="cancelClaimData.selected_reason" class="form-control select2" data-placeholder="Choose a Reason">
                                <option :value="null" disabled>- Select a Reason -</option>
                                <option v-if="cancelClaimData.claim.type !== 'parking'" value="Paid on Site">Paid on Site</option>
                                <option v-if="cancelClaimData.claim.type !== 'parking'" value="Duplicate Claim">Duplicate Claim</option>
                                <option v-if="cancelClaimData.claim.type === 'parking'" value="Client Request">Client Request</option>
                                <option v-if="cancelClaimData.claim.type === 'parking'" value="Contractor/Working on Site">Contractor/Working on Site</option>
                                <option v-if="cancelClaimData.claim.type === 'parking'" value="Genuine Customer">Genuine Customer</option>
                                <option v-if="cancelClaimData.claim.type === 'parking'" value="Hotel Guest">Hotel Guest</option>
                                <option v-if="cancelClaimData.claim.type === 'parking'" value="Special Circumstances">Special Circumstances</option>
                                <option v-if="cancelClaimData.claim.type === 'parking'" value="Staff">Staff</option>
                                <option v-if="cancelClaimData.claim.type === 'parking'" value="Visitor">Visitor</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" v-model="cancelClaimData.notes" placeholder="If you haven't selected a reason above, please explain why this case should be cancelled so that our team may review..." rows="5"></textarea>
                        </div>
                        <div class="form-group">
                            <label class="ckbox">
                                <input v-model="cancelClaimData.send_cancellation_notice" type="checkbox">
                                <span>Send Cancellation Notice to Motorist?</span>
                            </label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <submit-button type="button" class="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium" :clicked="cancelClaimData.processing" @click="processCancellation" text="Submit Request"></submit-button>
                        <button type="button" class="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium" @click="clearCancellation" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader";
import {ExportToCsv} from 'export-to-csv';
import {DateTime} from "luxon";
import XLSX from 'xlsx';
import saveAs from 'file-saver';
import SubmitButton from "@/components/SubmitButton";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "Claims",
    components: {
        SubmitButton,
        Loader
    },
    props: {
        type: {
           type: String,
           required: false,
           default: ''
        },
        types: {
            type: Array,
            required: false,
            default: []
        },  
        site: {
            type: Number,
            required: false,
            default: null
        },
        previous: {
            type: Boolean,
            required: false,
            default: false
        },
        current: {
            type: Boolean,
            required: false,
            default: false
        },
        paid: {
            type: Boolean,
            required: false,
            default: false
        },
        outstanding: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data(){
        return {
            filters: {
                start_date: null,
                end_date: null,
                type: [],
                status: [],
                plate: null,
                site_id: [],
                sort_by: 'any'
            },
            sites: [],
            statuses: [],
            generating: false,
            claims: [],
            errors: [],
            viewingClaimId: -1,
            viewingClaim: false,
            claim: null,
            cancelClaimData: {
                claim: null,
                selected_reason: null,
                notes: null,
                send_cancellation_notice: false,
                processing: false
            }
        }
    },
    mounted() {
        axios.get(`https://server.varsanpr.com/v2/client/${this.$store.state.auth.user.selectedClient}/sites`, {
            headers: authHeader()
        })
            .then(response => {
                this.sites = response.data.sites;
                $(".select2").select2();
            })
            .catch(error => {
                console.error(error);
                alert("Unable to update site list.");
            });

        axios.get(`https://server.varsanpr.com/api/claims/statuses?client_id=${this.$store.state.auth.user.selectedClient}`, {
            headers: authHeader()
        })
        .then(response => {
            this.statuses = response.data.statuses;
            this.handleProps();
        })
        .catch(error => {
            this.$error("Failed to load claim statuses", error);
        })
    },
    methods: {
        handleProps(){
            switch(this.type){
                case 'driveoff':
                    this.filters.type.push('driveoff');
                    break;
                case 'nmop':
                    this.filters.type.push('nmop');
                    break;
                case 'parking':
                    this.filters.type.push('parking');
                    break;
            }
            if(this.site !== null){
                this.filters.site_id.push(this.site);
            }

            if(this.type.length > 0 || this.site !== null){
                this.generate();
            }

            if(this.current){
                this.filters.start_date = DateTime.local().startOf('month').toFormat('yyyy-MM-dd');
                this.filters.end_date = DateTime.local().endOf('month').toFormat('yyyy-MM-dd');
            }

            if(this.previous){
                this.filters.start_date = DateTime.local().minus({months: 1}).startOf('month').toFormat('yyyy-MM-dd');
                this.filters.end_date = DateTime.local().minus({months: 1}).endOf('month').toFormat('yyyy-MM-dd');
            }

            if(this.paid){
                this.filters.status.push('Paid');
            }
            if(this.outstanding){
                this.filters.status = this.statuses.filter((s) => {
                    return !['Paid', 'Paid on Site', 'Cancelled', 'AUTOCANCELLATION'].includes(s);
                });
            }

            if(this.types.length > 0){
                this.filters.type = this.types;
            }

            if(this.filters.status.includes('Paid')){
                this.filters.sort_by = 'paid';
            }

            if(this.current || this.previous || this.paid || this.outstanding){
                this.generate();
            }
        },
        cancelClaim(claim){
            this.cancelClaimData.claim = claim;
            $('#cancelClaimModal').modal('toggle')

            // const {value: reason} = await Swal.fire({
            //     title: `Please tell us why you wish to cancel ${claim.reference}`,
            //     input: 'textarea',
            //     inputPlaceholder: 'Please explain why you wish to cancel this claim...',
            //     showCancelButton: true
            // });
            //
            // if(reason){

            // }
        },
        clearCancellation(){
            this.cancelClaimData = {
                claim: null,
                selected_reason: null,
                notes: null,
                send_cancellation_notice: false,
                processing: false
            }
        },
        processCancellation(){
            this.cancelClaimData.processing = true;
            axios.post(`https://server.varsanpr.com/v2/cancellation/request`, {
                reason: this.cancelClaimData.notes,
                selected_reason: this.cancelClaimData.selected_reason,
                send_notice: this.cancelClaimData.send_cancellation_notice,
                id: this.cancelClaimData.claim.id,
                client_id: this.$store.state.auth.user.selectedClient
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.status !== 200){
                    throw new Error('Unable to submit cancellation request');
                }
                this.$success("Cancellation Request submitted.");
                $('#cancelClaimModal').modal('toggle');
                this.clearCancellation();
            })
            .catch(error => {
                this.cancelClaimData.processing = false;
                this.$error("Unable to submit cancellation request.", error);
            });
        },
        viewClaim(id){
            $('#fuelModal').modal('toggle')
            axios.get(`https://server.varsanpr.com/v2/report/claims/${id}?client_id=${this.$store.state.auth.user.selectedClient}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.claim = response.data.claim;
                    this.claim.make = "";
                    this.claim.colour = "";
                    this.claim.model = "";
                    axios.get(`https://server.varsanpr.com/v2/external/dvsa/${this.claim.vehicle.plate}`, {
                        headers: authHeader()
                    })
                    .then(response => {
                        this.claim.make = response.data.vehicle.make;
                        this.claim.model = response.data.vehicle.model;
                        this.claim.colour = response.data.vehicle.colour;
                        this.viewingClaim = true;
                    })
                })
                .catch(error => {
                    this.$error('Unable to load claim details.', error);
                });
        },
        closeClaim(){
          this.viewingClaim = false;
          this.viewingClaimId = -1;
          this.claim = null;
        },
        viewClaimParking(id){
            $('#parkingModal').modal('toggle')
            axios.get(`https://server.varsanpr.com/v2/report/claims/${id}?client_id=${this.$store.state.auth.user.selectedClient}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.claim = response.data.claim;
                    this.claim.make = "";
                    this.claim.colour = "";
                    this.claim.model = "";
                    axios.get(`https://server.varsanpr.com/v2/external/dvsa/${this.claim.vehicle.plate}`, {
                        headers: authHeader()
                    })
                        .then(response => {
                            this.claim.make = response.data.vehicle.make;
                            this.claim.model = response.data.vehicle.model;
                            this.claim.colour = response.data.vehicle.colour;
                            this.viewingClaim = true;
                        })
                })
                .catch(error => {
                    this.$error('Unable to load claim details.', error);
                });
        },
        generate(){
            // TODO: If status doesn't include Paid then force sort by to be any
            this.generating = true;
            let data = {
                client_id: this.$store.state.auth.user.selectedClient
            };
            if(this.filters.site_id.length > 0){
                data.sites = this.filters.site_id;
            }else{
                data.sites = [];
            }
            if(this.filters.plate !== null){
                if(this.filters.plate.length > 0){
                    data.plate = this.filters.plate;
                }
            }
            if(this.qc(this.filters.start_date)){
                data.start = DateTime.fromFormat(this.filters.start_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
            }
            if(this.qc(this.filters.end_date)){
                data.end = DateTime.fromFormat(this.filters.end_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
            }
            if(this.filters.status.length > 0){
                data.statuses = this.filters.status;
            }else{
                data.statuses = [];
            }
            if(this.filters.type.length > 0){
                data.types = this.filters.type;
            }else{
                data.types = [];
            }
            if(this.filters.sort_by === 'paid' && !this.filters.status.includes('Paid')){
                this.filters.sort_by = 'any';
            }
            data.sort_by = this.filters.sort_by;
            axios.post('https://server.varsanpr.com/api/reports/claims', data, {
                headers: authHeader()
            })
            .then(response => {
                // if(this.filters.paid && !this.filters.outstanding && !this.filters.cancelled){
                //     response.data.claims.sort((a,b) => {
                //         if(a.date_paid > b.date_paid){
                //             return -1;
                //         }
                //         if(a.date_paid < b.date_paid){
                //             return 1;
                //         }
                //         return 0.
                //     });
                // }
                response.data.claims.forEach((c) => {
                    let receiptDate = DateTime.fromSeconds(c.date_received)
                    c.date_received = receiptDate.toFormat('dd/MM/yyyy');
                    if(c.date_paid){
                        let paymentDate = DateTime.fromSeconds(parseInt(c.date_paid));
                        c.date_paid = paymentDate.toFormat('dd/MM/yyyy');
                        c.daysTillPayment = Math.abs(receiptDate.diff(paymentDate, 'days').days);
                    }
                });
                this.claims = response.data.claims;
                this.errors = response.data.errors || [];
                this.generating = false;
            })
            .catch(error => {
                this.generating = false;
                console.error(error);
                console.log(error.response.data);
                if(error?.response?.data?.message){
                    this.errors.push(error.response.data.message);
                }else{
                    this.errors.push("There was an issue generating the report. Please try again later!");
                }
            });
        },
        qc(val){
            if(val === null) return false;
            if(val === undefined) return false;

            if(typeof val === 'string'){
                if(val.length < 1) return false;
            }

            return true;
        },
        exportCsv(){
            let data = [];
            for(let claim of this.claims){
                data.push({
                    "Type": claim.type,
                    "Reference": claim.reference,
                    "Site Name": claim.site.name,
                    "Vehicle Registration Plate": claim.registration,
                    "Date Received": claim.date_received,
                    "Status": claim.status,
                    "Cancellation Reason": claim.cancellation_reason || "",
                    // "Value": `${this.$n(claim.value, 'currency', this.user.locale)}`
                    "Value": claim.value
                });
            }

            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'VARS Claim Report',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

            const csvExporter = new ExportToCsv(options);

            csvExporter.generateCsv(data);
        },
        async exportExcel(){
            console.log("Exporting Excel");
            let data = [];
            for(let claim of this.claims){
                data.push({
                    "Type": claim.type,
                    "Reference": claim.reference,
                    "Site Name": claim.site.name,
                    "Vehicle Registration Plate": claim.registration,
                    "Date Received": claim.date_received,
                    "Status": claim.status,
                    "Cancellation Reason": claim.cancellation_reason || "",
                    "Payment Date": claim.date_paid || "",
                    // "Value": `${this.$n(claim.value, 'currency', this.user.locale)}`
                    "Value": claim.value

                });
            }
            let filename = 'VARSClaimsReport';
            let book = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(book, ws, 'ClaimsReport');
            var wopts = { bookType:'xlsx', bookSST:false, type: 'array'};
            var wbout = XLSX.write(book,wopts);
            /* the saveAs call downloads a file on the local machine */
            saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${filename}.xlsx`);
        },
        thisWeek(){
            this.filters.start_date = DateTime.local().startOf('week').toFormat('yyyy-MM-dd');
            this.filters.end_date = DateTime.local().endOf('week').toFormat('yyyy-MM-dd');
        },
        thisMonth(){
            this.filters.start_date = DateTime.local().startOf('month').toFormat('yyyy-MM-dd');
            this.filters.end_date = DateTime.local().endOf('month').toFormat('yyyy-MM-dd');
        },
        thisQuarter(){
            this.filters.start_date = DateTime.local().startOf('quarter').toFormat('yyyy-MM-dd');
            this.filters.end_date = DateTime.local().endOf('quarter').toFormat('yyyy-MM-dd');
        },
        thisYear(){
            this.filters.start_date = DateTime.local().startOf('year').toFormat('yyyy-MM-dd');
            this.filters.end_date = DateTime.local().endOf('year').toFormat('yyyy-MM-dd');
        }
    },
    computed: {
        numberOfDriveOff: function(){
            return this.claims.filter((e) => {
                return e.type === "driveoff";
            }).length;
        },
        numberOfNoMeans: function(){
            return this.claims.filter((e) => {
                return e.type === "nmop" || e.type === 'escalated';
            }).length;
        },
        numberOfParking: function(){
            return this.claims.filter((e) => {
                return e.type === "parking";
            }).length;
        },
        totalPaid: function(){
            let paid = this.claims.filter((e) => {
                return e.status === "Paid";
            });
            let total = 0;
            for(let c in paid){
                let v = Number.parseFloat(paid[c].value || 0);
                total += isNaN(v) ? 0 : v;
            }
            return Number.parseFloat(total).toFixed(2);
        },
        totalUnrecovered: function(){
            let unrecovered = this.claims.filter((e) => {
                return e.status !== "Cancelled" && e.status !== "Paid" && e.type !== "parking";
            });
            console.log("Checking", unrecovered.length, "claims for unrecovered monies");
            let total = 0;
            for(let c in unrecovered){
                let v = Number.parseFloat(unrecovered[c].value || 0);
                total += isNaN(v) ? 0 : v;
            }
            return Number.parseFloat(total).toFixed(2);
        },
        created_at: function(){
            if(this.claim.events[0] !== undefined){
                return DateTime.fromSeconds(parseInt(this.claim.events[0].created_at)).toFormat("dd/MM/yyyy HH:mm")
            }
            if(this.claim.incident_timestamp !== null){
                return DateTime.fromSeconds(parseInt(this.claim.incident_timestamp)).toFormat("dd/MM/yyyy HH:mm")
            }else{
                return DateTime.fromSeconds(parseInt(this.claim.date_received)).toFormat("dd/MM/yyyy HH:mm")
            }
        },
        maxStay: function(){
            let hours = ((this.claim.max_stay || 0) / 60);
            let rHours = Math.floor(hours);
            let minutes = (hours-rHours) * 60;
            let rMinutes = Math.round(minutes);
            return `${rHours} Hours ${rMinutes} Minutes`;
        },
        overstayBy: function(){
            if(this.claim) {
                if (this.claim.events) {
                    if (this.claim.events[0] && this.claim.events[1]) {
                        let entry = DateTime.fromSeconds(this.claim.events[0].created_at);
                        let exit = DateTime.fromSeconds(this.claim.events[1].created_at);

                        let diff = exit.diff(entry, 'minutes').toObject();
                        let over = Math.abs(diff.minutes) - this.claim.max_stay;
                        if (over < 0) {
                            over = 0;
                        }

                        let hours = (over / 60);
                        let rHours = Math.floor(hours);
                        let minutes = (hours - rHours) * 60;
                        let rMinutes = Math.round(minutes);
                        return `${rHours} Hours ${rMinutes} Minutes`;
                    }
                }
            }
        },
        entry: function(){
            if(this.claim){
                if(this.claim.events){
                    if(this.claim.events[0]){
                        return DateTime.fromSeconds(this.claim.events[0].created_at).toFormat("dd/MM/yyyy HH:mm:ss");
                    }
                }
            }
        },
        exit: function(){
            if(this.claim) {
                if (this.claim.events) {
                    if (this.claim.events[1]) {
                        return DateTime.fromSeconds(this.claim.events[1].created_at).toFormat("dd/MM/yyyy HH:mm:ss");
                    }
                }
            }
        },
        forecastPaid: function(){
            if(this.paid && this.current){
                let startOfMonth = DateTime.local().startOf('month');
                let today = DateTime.local();
                let daysSoFar = today.diff(startOfMonth, 'days').toObject().days;
                let daysInTotal = DateTime.local().endOf('month').diff(startOfMonth, 'days').toObject().days;
                return 'Forecast: £' + ((this.totalPaid / daysSoFar) * daysInTotal).toFixed(2);
            }
            return '';
        }
    }
}
</script>

<style scoped>
.table tbody tr td, .table thead tr th {
    color: #fff;
}
</style>
