<template>
     <div class="row">
        <div class="col-12">
            <loader :show="generating"></loader>
            <div v-if="!generating">

                <table width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                        <tr>
                            <th>Status</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(value, key) in statuses">
                            <td>{{key}}</td>
                            <td>{{value}}</td>
                        </tr>
                    </tbody>

                </table>    

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: 'ClaimStatuses',
    data(){
        return {
            statuses: {},
            generating: true
        }
    },
    mounted(){
        this.loadStatuses();  
    },
    methods: {
        loadStatuses(){
            this.generating = true;
            axios.post(`https://server.varsanpr.com/api/reports/claimstatus`, {
                client_id: this.$store.state.auth.user.selectedClient
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.statuses = response.data.statuses;
            })
            .catch(error => {
                this.$error("Failed to load report", error);
            })
            .finally(() => {
                this.generating = false;
            });
        }
    }
}
</script>